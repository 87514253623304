import { ChannelReferenceDto, LocalizedStringDto } from "./ReturnReasons";

export class ClaimReasonDto  
{
    id: string;
    code: string;

    localizations: Array<LocalizedStringDto>;
    channelReferences: Array<ChannelReferenceDto>;

    isRemoved: boolean;
    returnReasonId: string;

    constructor(){
        this.id = "";
        this.code = "";
        this.localizations = [];
        this.channelReferences = [];
        this.isRemoved = false;
        this.returnReasonId = "";
    }
}