import { ShipmentTrackingStatus } from "./ShipmentMetrics";

export class TrackingStepDto {
    uid = '';
    provider = '';
    regex = '';
    description = '';
    trackingStatus?: ShipmentTrackingStatus;
    stepStatus: StepStatus;

    constructor(){        
        this.stepStatus = StepStatus.NotSpecified;
    }
}

export enum StepStatus {
	NotFound = "NotFound",
	NotSpecified = "NotSpecified",
	Defined = "Defined",
	Skip = "Skip",
}