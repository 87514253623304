
import { IonPage, IonContent, IonSelectOption, IonSelect, loadingController, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import AdminMenu from "@/views/Admin/AdminMenu.vue";

import { defineComponent, onMounted, ref, Ref} from 'vue';

import moment from 'moment-timezone';

import { useOgoEvents } from '@/composables/useOgoEvents';

import { useAuthStore } from '@/store/authStore';
import { useErrorBox } from "@/components/errorBox";
import { useRouter } from 'vue-router';
import { eyeOutline, eyeOffOutline, } from "ionicons/icons";
import { useTrackingStore } from '@/store/trackingStore';
import { TrackingStepDto } from '@/models/trackingService/TrackingStepDto';
import { TrackingProviderDto } from '@/models/trackingService/TrackingProvider';

export default defineComponent({
  name: 'TrackingStepList',
  components: { 
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    IonSelect,
    IonSelectOption,
    AdminMenu,
  },
  setup () {

    const auth = useAuthStore();
    const trackingStore = useTrackingStore();

    const router = useRouter()

    const momentjs = (a: any): moment.Moment => { return moment(a); }

    const steps :Ref<Array<TrackingStepDto>> = ref([]);
    const provider :Ref<string|undefined> = ref(undefined);
    const providers :Ref<Array<TrackingProviderDto>> = ref([]);

    const { showError } = useErrorBox();



    const loading = async () => {
        const loading = await loadingController.create({ message: "Processing...",});
        await loading.present();
        return loading;
      };
      
    const init = async () => {
      const l = await loading();
      try {
        // get providers
        providers.value = await trackingStore.getProviders();

        // show all missing steps if no provider selected
        if (!provider.value) {
          steps.value = (await trackingStore.getStepsMissing()).sort((a, b) => a.regex.localeCompare(b.regex));
        } else {
          steps.value = (await trackingStore.getStepsByProvider(provider.value)).sort((a, b) => a.regex.localeCompare(b.regex));
        }

      } catch (error) {
        await showError(error, "Error");
      } finally {
        await l.dismiss();
      }
    }

    useOgoEvents({ trackingStepChanged: (dto)=> 
      {
        if(dto) {
          const index = steps.value.findIndex(s => s.uid == dto.uid);
          if(index >= 0) { steps.value[index] = dto; }
          else { steps.value.push(dto); }
        } else {
          init();
        } 
      }
    });

    onMounted(async () => {
        if(!auth.filteredRoles.includes("Admins")){
            await showError("Missing admins role", "Missing permission");
            return;
        }
        await init();
    });

    return {
      provider, providers, steps, init,
      momentjs,
      router, eyeOutline, eyeOffOutline
    }
  }
});

