
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonInput, IonTitle, 
  IonItem, IonSelect, IonSelectOption, IonToggle, IonLabel, IonButton, IonIcon, loadingController, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

import AdminMenu from "@/views/Admin/AdminMenu.vue";

import { computed, defineComponent, onMounted, ref, Ref } from 'vue';
import PageDescription from '@/components/PageDescription.vue';
//import CustomerPortalSettings from '@/views/PostPurchase/CustomerPortalSettings.vue';
//import WidgetSettings from '@/views/PostPurchase/WidgetSettings.vue';

import { useAuthStore } from '@/store/authStore';
import { useConfigStore } from '@/store/configStore';
import { onBeforeRouteLeave, useRouter } from 'vue-router';

import { LanguageDto } from '@/models/configService/Languages';
import { ChannelReferenceDto, LocalizedStringDto, ReturnReasonDto } from '@/models/configService/ReturnReasons';
//import { save } from 'ionicons/icons';
import ChannelSelect from "@/components/ChannelSelect.vue";
import { useErrorBox } from '@/components/errorBox';
import { stringToHash } from '@/utils/changeTracking';
import { cloudDoneOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'CustomerPortalSettingsPage',
  components: {
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    IonGrid, IonRow, IonCol, IonInput, IonTitle, IonItem, IonSelect, IonSelectOption, 
    IonIcon,
    IonToggle, IonLabel,
    IonButton,
    PageDescription,
    AdminMenu,
    ChannelSelect,
  },
  setup (){
    const auth = useAuthStore();
    const configStore = useConfigStore();
    const router = useRouter();
    const loaded = ref(false); 
    const allLanguages:Ref<Array<LanguageDto>> = ref([]);
    const currentReturnReason:Ref<ReturnReasonDto> = ref(new ReturnReasonDto);
    const lang:Ref<string> = ref("en");
    const { showError } = useErrorBox();

    const loading = async () => {
        const loading = await loadingController.create({
          cssClass: "my-custom-class",
          message: "Processing...",
          duration: undefined,
        });

        await loading.present();
        return loading;
      };
      
    onMounted(async () => {
      if(!auth.filteredRoles.includes("Admins"))
        return;
      
      const l = await loading();
      try {
        const id = router.currentRoute.value.params["id"].toString();
        
        if(id != null && id != "") {
            currentReturnReason.value = await configStore.getReturnReason(id);
        }

        allLanguages.value = await configStore.getLanguages();

      } catch (error) {
        await showError(error, "Error loading return reasons details");
      } finally {
        await l.dismiss();
      }

      loaded.value = true;
      savedHash.value = hashComponentState.value;

    });

    const save = async () => {

      if(!auth.filteredRoles.includes("Admins"))
        return;

      const l = await loading();
      try {
        if(currentReturnReason.value.id == null || currentReturnReason.value.id == "")
            await configStore.createReturnReason(currentReturnReason.value);
        else
            await configStore.updateReturnReason(currentReturnReason.value.id, currentReturnReason.value);

      } catch (error) {
        await showError(error, "Error saving return reasons details");
      } finally {
        await l.dismiss();
      }

      savedHash.value = hashComponentState.value;
    };

    const removeLocalization = async (locale:string) => {
        if(currentReturnReason.value != null && currentReturnReason.value.localizations != null
            && currentReturnReason.value.localizations.find(i=>i.lang == locale) != null)
        {
            const arrayindex = currentReturnReason.value.localizations.findIndex(i=>i.lang == locale);
                if(arrayindex > -1)
                    currentReturnReason.value.localizations.splice(arrayindex, 1);
        }

    };
    const addLocalization = async (event:any) => {
        if(currentReturnReason.value != null)
        {
            if(currentReturnReason.value.localizations == null)
                currentReturnReason.value.localizations = new Array<LocalizedStringDto>();
            const newloc = new LocalizedStringDto();
            newloc.lang = event.detail.value;
            currentReturnReason.value.localizations.push(newloc);
        }

    }

    const translateEmpty = async () => {
      const l = await loading();
      try {
        const enTxt = currentReturnReason.value.localizations.find(i=>i.lang == "en")?.context;
        const languages = currentReturnReason.value.localizations
          .filter(i=>i.lang != "en" && !i.context)
          .map(i => i.lang )
          .join(",");
        
        if(!enTxt || !languages)
          throw new Error("No empty fields to translate");
      
        const translations = await configStore.translate(enTxt, "en", languages)
        translations.forEach(element => {
          const empty = currentReturnReason.value.localizations.find(i=>i.lang == element.language);
          if(empty && !empty.context && element.text)
          empty.context = element.text;        
        });
    
      } catch (error) {
        await showError(error, "Error translating text");
      } finally {
        await l.dismiss();
      }
    }

    const removeChannel = async (removedId:number) => {
      if(!currentReturnReason.value)
        return;
        currentReturnReason.value.channelReferences = currentReturnReason.value.channelReferences.filter(i => i.channelId != removedId);
    }

    const addChannel = async (id:number, name:string) => {
      if(!currentReturnReason.value)
        return;

        const channelReference = new ChannelReferenceDto();
        channelReference.channelId = id;
        channelReference.name = name;
        currentReturnReason.value.channelReferences.push(channelReference);
    }

    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {

      return stringToHash(JSON.stringify({
        currentReturnReason: currentReturnReason.value,
      }));
    });

    const isDirty = computed(() => {
      if (savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion

    onBeforeRouteLeave(() => {
        if(isDirty.value)
          return window.confirm('You have unsaved changes! Do you want to leave?');
        
        return true;
      });
      
    return {
      translateEmpty, isDirty, cloudDoneOutline,
        loaded, currentReturnReason, lang, allLanguages, save, removeLocalization, addLocalization, addChannel, removeChannel
    }

}
});

