
  import { Link } from '@/components/PageDescription.vue';
  import { IonGrid, IonRow, IonCol, IonButton} from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { useRouter } from 'vue-router';

  export default defineComponent({
    name: 'ReturnMenu',
    components: { 
        IonGrid,
        IonRow, 
        IonCol, 
        IonButton,      
    },
    setup(props, {slots}){
      const router = useRouter();

      const links: Array<Link> = [{
        active: false,
        title: "Dashboard",
        url: "/returns/dashboard",
      },{
        active: false,
        title: "Returns requests",
        url: "/returns/requests",
      },{
        active: false,
        title: "Settings",
        url: "/returns/settings",
      },{
        active: false,
        title: "Return Delivery Types",
        url: "/returns/deliveryTypes",
      },{
        active: false,
        title: "Return rules",
        url: "/returns/rules",

      },{
        active: false,
        title: "Customer Portal Settings",
        url: "/returns/portalsettings",
      }];

      const isActive = (url: string) =>{
        const currentPath = router.currentRoute.value.path;
        return currentPath == url;
      }

      const defaultSlotUsed = () => {
        const defaultSlot = slots?.default;
        return !!defaultSlot;
      }

      return {router,isActive, links, defaultSlotUsed,}
    }
  });
