import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Login from '@/views/Auth/Login.vue';
import Register from '@/views/Auth/Register.vue';
import ResetWithToken from '@/views/Auth/ResetWithToken.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';
import VerifyEmailWithToken from '@/views/Auth/VerifyEmail.vue';
import ReturnPage from '@/views/Auth/ReturnPage.vue';
import ToPage from '@/views/Auth/To.vue';

import UserUsagePage from '@/views/Auth/UserUsagePage.vue';
import WebHookDetailsPage from '@/views/Merchant/WebHooks/WebHookDetailsPage.vue';

import ReturnDetails from '@/views/Return/ReturnDetails.vue'
import ReturnDashboard from '@/views/Return/ReturnDashboard.vue'
import TrackingDashboard from '@/views/Admin/TrackingDashboardPage.vue'
import PostPurchaseSettingsPage from '@/views/PostPurchase/SettingsPage.vue'
import ReturnRules from '@/views/Return/ReturnRules.vue'
import ReturnRuleDetails from '@/views/Return/ReturnRuleDetails.vue'
import ReturnDeliveryTypes from '@/views/Return/ReturnDeliveryTypes.vue'
import ReportsList from '@/views/Report/ReportsList.vue'
import ShippingDashboardPage from '@/views/Dashboard/ShippingDashboardPage.vue'
import InvoiceDashboardPage from '@/views/Dashboard/InvoiceDashboardPage.vue'
import FrameDetails from '@/views/Notification/FrameDetails.vue'
import TemplateDetails from '@/views/Notification/TemplateDetails.vue'
import ChannelList from '@/views/Admin/ChannelList.vue'
import TrackingDetails from '@/views/Tracking/TrackingDetails.vue'
import ReturnReasonsAdmin from '@/views/Admin/ReturnReasonsAdmin.vue'
import ReturnReasonAdminDetails from '@/views/Admin/ReturnReasonsDetails.vue';
import ServiceStatus from '@/views/Admin/ServiceStatus.vue'
import Co2Dashboard from '@/views/Co2/Co2Dashboard.vue'
import { useAuthStore } from '@/store/authStore';
import ReturnDashboardPage from '@/views/Dashboard/ReturnDashboardPage.vue'
import StaffSearch from '@/views/Search/StaffSearch.vue'
import CustomerPortalSettingsPage from '@/views/PostPurchase/CustomerPortalSettingsPage.vue'
import ReturnDeliveryTypeList from '@/views/Admin/ReturnDeliveryTypeList.vue'
import ReturnDeliveryTypeDetails from '@/views/Admin/ReturnDeliveryTypeDetails.vue'
import CampaignList from '@/views/Feedback/CampaignList.vue';
import CampaignDetails from '@/views/Feedback/CampaignDetails.vue';
import Returns from '@/views/Return/Returns.vue';
import ReturnRequestsPage from '@/views/Return/ReturnRequestsPage.vue';
import CameraSamplePage from '@/views/Sample/CameraSamplePage.vue';
import ReturnRequestDetails from '@/views/Return/ReturnRequestDetails.vue';
import WarehouseProductList from '@/views/Admin/WarehouseProductList.vue';
import WarehouseProductDetails from '@/views/Admin/WarehouseProductDetails.vue';
import InvoiceRowsPage from '@/views/Admin/InvoiceRowsPage.vue';

import TransactionalMessageList from '@/views/Admin/TransactionalMessageList.vue';
import TransactionalMessageDetails from '@/views/Admin/TransactionalMessageDetails.vue';

import CostDetailsAdmins from '@/views/Admin/CostDetailsAdminsPage.vue';
import CostDetailsDashboard from '@/views/Dashboard/CostDetailsPage.vue';
import ClaimReasonsAdmin from '@/views/Admin/ClaimReasonsAdmin.vue';
import ClaimReasonsDetails from '@/views/Admin/ClaimReasonsDetails.vue';

import PurchaseOrdersPage from '@/views/PurchaseOrder/PurchaseOrdersPage.vue';
import TrackingAlertsPage from '@/views/Admin/TrackingAlertsPage.vue';

import TrackingStepList from '@/views/Admin/Tracking/TrackingStepList.vue';
import TrackingStepDetails from '@/views/Admin/Tracking/TrackingStepDetails.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'ShippingDashboardPage',
    component: ShippingDashboardPage,
    meta: { requiresAuth: true, menuRoot: "dashboard", merchantRequired: true },
  },
  {
    path: '/inbound/purchaseorders',
    name: 'PurchaseOrdersPage',
    component: PurchaseOrdersPage,
    meta: { requiresAuth: true, menuRoot: "inbound", merchantRequired: true },
  },
  {
    path: '/admin/invoice/browser',
    name: 'CostDetailsAdmins',
    component: CostDetailsAdmins,
    meta: { requiresAuth: true, menuRoot: "admin", merchantRequired: false },
  },
  {
    path: '/dashboard/costdetails',
    name: 'CostDetailsDashboard',
    component: CostDetailsDashboard,
    meta: { requiresAuth: true, menuRoot: "dashboard", merchantRequired: false },
  },
  {
    path: '/dashboard/co2',
    name: 'Co2Dashborad',
    component: Co2Dashboard,
    meta: { requiresAuth: false, menuRoot: "dashboard", merchantRequired: true },
  },
  {
    path: '/dashboard/returns',
    name: 'ReturnDashboardPage',
    component: ReturnDashboardPage,
    meta: { requiresAuth: true, menuRoot: "dashboard", merchantRequired: true  },
  },
  {
    path: '/dashboard/invoice',
    name: 'InvoiceDashboardPage',
    component: InvoiceDashboardPage,
    meta: { requiresAuth: true, menuRoot: "dashboard", merchantRequired: true  },
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { requiresAuth: false },
  },
  {
    path: '/auth/return',
    name: 'ReturnPage',
    component: ReturnPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/to',
    name: 'ToPage',
    component: ToPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/user/usage',
    name: 'UserUsagePage',
    component: UserUsagePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { requiresAuth: false },
  },
  {
    path: '/resetWithToken',
    name: 'ResetWithToken',
    component: ResetWithToken,
    meta: { requiresAuth: false },
  },
  {
    path: '/verifyEmail',
    name: 'VerifyEmailWithToken',
    component: VerifyEmailWithToken,
    meta: { requiresAuth: false },
  },
  {
    path: '/returns/dashboard',
    name: 'ReturnDashboard',
    component: ReturnDashboard,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/returns/list',
    name: 'ReturnList',
    component: Returns,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/returns/requests',
    name: 'ReturnRequests',
    component: ReturnRequestsPage,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/returns/settings',
    name: 'ReturnSettings',
    component: PostPurchaseSettingsPage,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/returns/deliveryTypes',
    name: 'ReturnDeliveryTypes',
    component: ReturnDeliveryTypes,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/returns/rules',
    name: 'ReturnRules',
    component: ReturnRules,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/returns/portalsettings',
    name: 'CustomerPortalSettings',
    component: CustomerPortalSettingsPage,
    meta: { requiresAuth: true, menuRoot: "pps" },
  },
  {
    path: '/returns/rule/:id?',
    name: 'ReturnRuleDetails',
    component: ReturnRuleDetails,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/return/:id',
    name: 'ReturnDetails',
    component: ReturnDetails,
    meta: { requiresAuth: true, menuRoot: "pps" },
  },
  {
    path: '/returns/request/:id',
    name: 'ReturnRequestDetails',
    component: ReturnRequestDetails,
    meta: { requiresAuth: true, menuRoot: "pps" },
  },
  {
    path: '/admin/feedback/campaignlist/:channelId?',
    name: 'CampaignList',
    component: CampaignList,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/admin/feedback/campaigndetails/:id?/:channelId?/:revision?',
    name: 'CampaignDetails',
    component: CampaignDetails,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },

  {
    path: '/notification/frame/:channel/:id?',
    name: 'FrameDetails',
    component: FrameDetails,
    meta: { requiresAuth: true, menuRoot: "pps" },
  },
  {
    path: '/tracking/details/:channel/:id?',
    name: 'TrackingDetails',
    component: TrackingDetails,
    meta: { requiresAuth: true, menuRoot: "pps" },
  },
  {
    path: '/notification/template/:type/:key',
    name: 'TemplateDetails',
    component: TemplateDetails,
    meta: { requiresAuth: true, menuRoot: "pps" },
  },
  {
    path: '/merchant/webhook/:id',
    name: 'WebHookDetails',
    component: WebHookDetailsPage,
    meta: { requiresAuth: true, menuRoot: "merchant" },
  },
  {
    path: '/reports/',
    name: 'Reports',
    component: ReportsList,
    meta: { requiresAuth: true, menuRoot: "reports", merchantRequired: true  },
  },
  {
    path: '/warehouse',
    name: 'Warehouse',
    redirect: '/warehouse/channels',
  },
  {
    path: '/warehouse/channels',
    name: 'WarehouseChannels',
    component: ChannelList,
    meta: { requiresAuth: true, menuRoot: "admin"},
  },
  {
    path: '/warehouse/search',
    name: 'WarehouseStaffSearch',
    component: StaffSearch,
    meta: { requiresAuth: true, menuRoot: "warehouse"},
  },
  {
    path: '/admin',
    name: 'Admin',
    redirect: '/admin/channellist',
  },
  {
    path: '/admin/channellist',
    name: 'ChannelList',
    component: ChannelList,
    meta: { requiresAuth: true, menuRoot: "admin"},
  },
  {
    path: '/admin/trackingDashboard',
    name: 'TrackingDashboard',
    component: TrackingDashboard,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/returnReasons',
    name: 'ReturnReasons',
    component: ReturnReasonsAdmin,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/returnReason/:id?',
    name: 'ReturnReasonAdminDetails',
    component: ReturnReasonAdminDetails,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/claimReasons',
    name: 'ClaimReasons',
    component: ClaimReasonsAdmin,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/claimReason/:id?',
    name: 'ClaimReasonsDetails',
    component: ClaimReasonsDetails,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/servicestatus',
    name: 'ServiceStatus',
    component: ServiceStatus,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/warehouseProducts',
    name: 'WarehouseProductList',
    component: WarehouseProductList,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/warehouseProduct/:code?',
    name: 'WarehouseProductDetails',
    component: WarehouseProductDetails,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/returnDeliverytypes',
    name: 'ReturnDeliveryTypeList',
    component: ReturnDeliveryTypeList,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/returnDeliverytype/:id',
    name: 'ReturnDeliveryTypeDetails',
    component: ReturnDeliveryTypeDetails,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/invoice/rows',
    name: 'InvoiceRowsPage',
    component: InvoiceRowsPage,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/tracking/alerts',
    name: 'TrackingAlertsPage',
    component: TrackingAlertsPage,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/tracking/steps',
    name: 'TrackingStepList',
    component: TrackingStepList,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/tracking/step/:provider/:id',
    name: 'TrackingStepDetails',
    component: TrackingStepDetails,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/transactional',
    name: 'TransactionMessageList',
    component: TransactionalMessageList,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/transactional/:id',
    name: 'TransactionalMessageDetails',
    component: TransactionalMessageDetails,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/sample/camera',
    name: 'CameraSamplePage',
    component: CameraSamplePage,
    meta: { requiresAuth: false, menuRoot: "sample" },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const auth = useAuthStore();

  
  // Redirect to login page if not authenticated and trying to access a restricted page
  if (to.meta.requiresAuth && !auth.isAuthenticated) {
    // console.log("Not authenticated");
    next({ name: 'Login' });
  } else if(to.meta.merchantRequired && !auth.merchantId) {
    // console.log("Merchant required");
    if(auth.filteredRoles.includes("Admins"))
      next({ name: 'Admin' });
    else if(auth.filteredRoles.includes("Staff"))
      next({ name: 'Warehouse' });
    else
      next({ name: 'Login' });
  } else {
    // console.log("Authenticated");
    next();
  }
})


export default router
