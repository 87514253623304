import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page-layout"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_PageDescription = _resolveComponent("PageDescription")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    class: "ion-page",
    id: "main"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        hilight: _ctx.isDirty,
        pageName: "Tracking step details"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_back_button),
          _createVNode(_component_ion_button, {
            color: "tertiary",
            onClick: _ctx.save,
            disabled: !_ctx.isDirty
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: _ctx.icons.cloudDoneOutline
              }, null, 8, ["icon"]),
              _createTextVNode(" Save")
            ]),
            _: 1
          }, 8, ["onClick", "disabled"]),
          (_ctx.step)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                color: "danger",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.remove()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.icons.trashOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(" Delete")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["hilight"]),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          (_ctx.step)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_AdminMenu),
                _createVNode(_component_PageDescription, {
                  pageName: "Tracking step details",
                  pageDescription: "View and update tracking step details",
                  breadCrumbs: [{title: 'List all steps', active: false, url: '/admin/tracking/steps'},{title: 'View details', active: true, url: '/home'}]
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_input, {
                      modelValue: _ctx.step.provider,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.step.provider) = $event)),
                      readonly: "",
                      "label-placement": "stacked",
                      label: "Provider"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_input, {
                      modelValue: _ctx.step.regex,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.step.regex) = $event)),
                      "label-placement": "stacked",
                      label: "Regular expression"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_input, {
                      modelValue: _ctx.step.description,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.step.description) = $event)),
                      "label-placement": "stacked",
                      label: "Additional description"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select, {
                      modelValue: _ctx.step.stepStatus,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.step.stepStatus) = $event)),
                      interface: "popover",
                      "label-placement": "stacked",
                      label: "Step status"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepStatusOptions, (option) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: option.code,
                            value: option.code
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(option.title), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select, {
                      modelValue: _ctx.step.trackingStatus,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.step.trackingStatus) = $event)),
                      interface: "popover",
                      "label-placement": "stacked",
                      label: "Tracking status"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trackingStatusOptions, (option) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: option.code,
                            value: option.code
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(option.title), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_page_footer)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}