
import { computed, defineComponent, onMounted, ref, Ref } from 'vue';
import { useRouter } from 'vue-router';

import { IonPage, IonContent, IonInput,
  IonItem, IonButton, IonIcon, loadingController,
  IonSelect,
  IonSelectOption,
  IonBackButton, } from '@ionic/vue';
  import { trashOutline, cloudDoneOutline } from 'ionicons/icons';

import moment from 'moment-timezone';

import { eventBus } from '@/composables/useOgoEvents';

import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import PageDescription from '@/components/PageDescription.vue';
import { useErrorBox } from '@/components/errorBox';

import AdminMenu from "@/views/Admin/AdminMenu.vue";

import { useAuthStore } from '@/store/authStore';
import { EMessageRecipientType, getMessageRecipientStatusName, messageApprovalOptions, messageRecipientTypeOptions, } from '@/models/notificationService/Transactional';

import { stringToHash } from '@/utils/changeTracking';
import { useTrackingStore } from '@/store/trackingStore';
import { StepStatus, TrackingStepDto } from '@/models/trackingService/TrackingStepDto';


export default defineComponent({
  name: 'TrackingStepDetails',
  components: {
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    IonInput, 
    IonItem, 
    IonIcon,
    IonBackButton,
    IonButton,
    IonSelect,
    IonSelectOption,
    PageDescription,
    AdminMenu,
  },
  setup (){
    const auth = useAuthStore();
    const trackingStore = useTrackingStore();
        
    const router = useRouter();
    const step:Ref<TrackingStepDto|undefined> = ref(undefined);
    
    const { showError } = useErrorBox();

    const momentjs = (a: any): moment.Moment => { return moment(a); }

    const loading = async () => {
        const loading = await loadingController.create({ message: "Processing...",});
        await loading.present();
        return loading;
      };

    const stepStatusOptions = [
      { code: StepStatus.NotFound, title: "Not found" },
      { code: StepStatus.NotSpecified, title: "Not yet specified" },
      { code: StepStatus.Defined, title: "Step is defined" },
      { code: StepStatus.Skip, title: "Skip this step" },
      ]

    const trackingStatusOptions = [
      { code: "Exception", title: "Exception" },
      { code: "Booked", title: "Booked" },
      { code: "InTransit", title: "In transit" },
      { code: "WithCourier", title: "WithCourier" },
      { code: "PickUpReady", title: "PickUpReady" },
      { code: "DeliveryAttempted", title: "DeliveryAttempted" },      
      { code: "Delivered", title: "Delivered" },
      { code: "Returned", title: "Returned" },
      ]

    onMounted(async () => {
      if(!auth.filteredRoles.includes("Admins"))
        return;
      
      const l = await loading();
      try {
        const provider = router.currentRoute.value.params["provider"].toString();
        const id = router.currentRoute.value.params["id"].toString();
        
        step.value = await trackingStore.getStep(provider,id);

      } catch (error) {
        await showError(error, "Error loading step details");
      } finally {
        await l.dismiss();
      }

      savedHash.value = hashComponentState.value;
    });

    const save = async () => {
      if(!step.value) return

      const l = await loading();
      try {
        await trackingStore.updateStep(step.value);
        savedHash.value = hashComponentState.value;
        eventBus.emit('trackingStepChanged', step.value);
      } catch (error) {
        await showError(error, "Error saving step details");
      } finally {
        await l.dismiss();
      }      
    };

    const remove = async () => {
      if(!step.value) return

      const l = await loading();
      try {
        await trackingStore.removeStep(step.value.provider, step.value.uid);
        router.back();
      } catch (error) {
        await showError(error, "Error removing step");
      } finally {
        await l.dismiss();
      }      
    };


    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {

      return stringToHash(JSON.stringify({
        step: step.value,
      }));
    });

    const isDirty = computed(() => {
      if (savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion


    return {
      isDirty, step,
      icons: { cloudDoneOutline, trashOutline, },
      save, remove, momentjs,
      messageRecipientTypeOptions, messageApprovalOptions, stepStatusOptions, trackingStatusOptions,
      EMessageRecipientType, getMessageRecipientStatusName,
    }

}
});

